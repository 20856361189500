/* general properties */
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%
}


/* app backdrop */
.App-backdrop{
  position: relative;
}

.App-backdrop-portfolio{
  background-color: #383a3c31;
}


.App-cover{
    max-width: 100%;
    /* height: 100vh; */
    height: auto;
    width: auto\9; 
    justify-content: center;
    align-items: center;
    opacity: 0.1;
    filter: grayscale(100%);
    
}

.App-cover-hide{
  visibility: hidden;
}

.App-box {
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	font-size: 10px;
}



.App-content{
  position: absolute;
  width: 100%;
}

.App {
  text-align: center;
  color: white;
  display: flex;
	flex-direction: column;
}

/* app user card */

.card {
  height: 200px;
  width: 350px;
  display: flex;
  flex-direction: row;
  border: rgba(255, 255, 255, 0.123) 1px solid;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.39);
  -webkit-backdrop-filter: blur(3px);
	position: relative;
}

#slide {
  position: absolute;
  font-family: "Questrial", sans-serif;
  font-size: 20px;
  line-height: 25px;
  background: var(--base);
  left: -500px;
  height: 100%;
  width: 100%;
  z-index: 11;
}

.slide-open {
  -webkit-animation: slide 0.5s forwards;
  -webkit-animation-delay: 0.1s;
  animation: slide 1.0s forwards ease-in;
  animation-delay: 0.1s;
}

.slide-close {
  -webkit-animation: slide 0.5s backwards;
  -webkit-animation-delay: 0.1s;
  animation: slide 1.0s backwards ease-in;
  animation-delay: 0.1s;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes close {
  100% {
    right: 0;
  }
}

@-webkit-keyframes close {
  100% {
    right: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

.center-intro {
  background: #3f4447;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.39);
  /* flex: 1; */
	flex-basis: 70%;

}

.avatar-box {
  box-sizing: border-box;
}

.avatar-box img {
  height: 150px;
  /* align-self: flex-start; */
}

#logo {
  width: 100px;
  opacity: 0.1;
}

#code {
  font-size: 40px;
}

#bottom {
  background: url("./images/prism.png");
  background-repeat: repeat;
  height: 100%;
  position: relative;
}

#bottom p {
  z-index: 10;
  font-family: "Orbitron", sans-serif;
}

.overlay {
  background: rgba(63, 68, 71, 0.5);
  /* width: 100%; */
  height: 100%;
  position: absolute;
  width: 100%;
}

.nav-intro {
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.39);
  display: flex;
  flex-direction: column;
  background: rgba(63, 68, 71, 0.404);
  /* height: 100px; */
  flex-basis: 30%;
}

.items {
  flex-basis: 25%;
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  list-style: none;
  /* padding: 5px; */
}

.icon {
  /* border: rgba(255, 255, 255, 0.062) 1px solid; */
  border-bottom: #3f4447 1px solid;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.24);
  width: 40px;
}

.items:hover {
  background: rgba(63, 68, 71, 0.733);
  cursor: pointer;
}

.icon-hover {
  color: darkgrey;
}

.nav {
  margin-left: 4px;
  display: flex;
  align-items: center;
  width: 100px;
  border-bottom: #3f4447 1px solid;
}

.last-nav,
.last-icon {
  border-top: unset;
}

.first-icon {
  border-top: #3f4447 1px solid;
}

.nav-box {
  /* opacity: 0; */
  transition: opacity 1.5s;
}

.nav-box-container {
  border: 1px rgba(255, 255, 255, 0.11) solid;
  height: 80%;
}

.col-items {
  padding: 10px;
  list-style: none;
  flex-basis: 50%;
}

.col-items:hover {
  background: rgba(63, 68, 71, 0.733);
  cursor: pointer;
}

.nav-box-container .icon {
  color: darkgrey;
}


/* portfolio page */
.portfolio-box{
  width: 100%;
  
}

.portfolio-page {
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  box-sizing: border-box;
  margin-top: 60px;
}


/* portfolio shelf closer */
#closer {
  font-size: 25px;
  justify-content: flex-end;
  padding: 10px;
  color: darkgrey;
  cursor: pointer;
}

/* portfolio shelf  items*/
.shelf {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  color: white;
  box-sizing: content-box;
  padding: 10px 20px;
}

.skill-container {
  /* border: rgba(255, 255, 255, 0.089) 1px solid; */
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.479);
  width: 300px;
  margin: 20px;
  flex-shrink: 1;
  background-color: #13141417;
  border-bottom-right-radius: 7px;
  border-bottom-left-radius: 7px;
}

.skill-container section > img {
  width: 300px;
  height: 150px;
} 

.skill-container p:nth-child(2) {
  font-family: "Orbitron", sans-serif;
}

.skill-container p:nth-child(3) {
  font-family: "Questrial", sans-serif;
}

.skill-container section {
  /* border: rgba(220, 220, 220, 0.082) 5px solid; */
}

.skill-container .links {
  text-decoration: underline;
  font-size: 10px;
  line-height: 20px;
  margin: 2px;
  margin-bottom: 7px;
}

.preview{
  margin-bottom: 10px;
}

.preview a:nth-child(1){
  margin-right: 10px;
}

.links {
  color: white;
  cursor: pointer;
  text-decoration: none !important;
}

.links:hover{
  font-weight: bolder;
  font-size: larger;
}





.icon-box{
  align-content: center;
  margin-bottom: 7px;
}

.icon-box img{
  width: 20px;
  margin-right: 5px;
}

.main {
  position: relative;
}



/* 
Header Styles */
.header {

  justify-content: space-between;
  padding: 10px;

}

nav li {
  text-decoration: none;
  list-style: none;
}

#terminal {
  font-size: 20px;
  color: white;
  cursor: pointer;
}

#avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: black;
  margin-right: 20px;
}

/* footer styles */
.footer {
	height: 10vh;
} 

.footer img{
	width: 20px;
	margin: 10px;
}


/* menu portal */
.menu-background{
	position: absolute;
	z-index: 9;
	padding-top: 20px;
	height: 10vh;
	  top: 4.5%;
  right: 3.9%;
	bottom: 20%;
	border-right: white solid 1px;
}

.menu-content{
	padding: unset;
  width: 50px;
  height: 100px;
	z-index: 10;
	/* background: #2325264b; */
	color: white;
	text-align: left;
}

.menu-content > p {
	/* background: black; */
	color: white;
	text-align: right;
	padding-right: 7px;
	border-bottom: 1px solid white;
	padding-left: unset;
}

/* ####################### */
/* portfolio videos */
.player-wrapper{
  width: 300px;
  height: 200px;
}

.player-video{
  position: absolute;
  width: 295px;
  height: 200px;
}

.overlay-image{
  width: 300px;
  height: 200px;
}


.link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: white;
  margin-top: 16px;
}

.linkspan {
  text-decoration: none;
}

@media screen and (min-width: 768px) {

  .portfolio-page {
    font-size: 12px;
    box-sizing: border-box;
    padding-right: 50px;
    padding-left: 150px;
  }

}