@import url("https://fonts.googleapis.com/css?family=Orbitron:500|Questrial&display=swap");

* {
  /* margin: 0; */
  padding: 0;
	/* box-sizing: border-box; */

}

:root {
	 /* min-height: 100vh; */
  --base: #2f3437;
  --spacing: 10px;
  --filter: grayscale(1) opacity(0.1);
  --bodyFont: {
	}
}


body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
	background: #232526ec;  
	margin: 0px;
	position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}